@import "./style";

.en-first-big {
    text-transform: capitalize;
}

a {
    color: #333;
}

input::-ms-clear {
    display: none;
}

.number-left-list(@mr, @number) {
    margin-top: -30px;
    width: 100%;
    .flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item {
        margin-top: 30px;
        width: calc((100% - @mr * (@number - 1)) / @number);
        margin-right: @mr;
        &:nth-child(@{number}n) {
            margin-right: 0;
        }
    }
}

// 根据个数计算一列显示多少个，居中对齐
.number-center-list(@mr, @number) {
    margin-top: -30px;
    width: 100%;
    .flex;
    justify-content: center;
    flex-wrap: wrap;
    .item {
        margin-top: 30px;
        width: calc((100% - @mr * (@number - 1)) / @number);
        margin-right: @mr;
        &:nth-child(@{number}n) {
            margin-right: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.index-module-title {
    margin-bottom: 49px;
    text-align: center;
    color: @blue;
    font-size: 30px;
    font-weight: bold;
}

// 基本简介
.bg-gray-title {
    width: 100%;
    height: 60px;
    background: #F7F7F7;
    padding-left: 20px;
    .flex;
    align-items: center;
    .text {
        border-left: 5px solid @blue;
        padding-left: 10px;
        font-size: 22px;
        font-weight: bold;
        color: @blue;
    }
    margin-bottom: 42px;
}

.article {
    width: 860px;
    background-color: #fafafa;
    padding: 50px 35px;
    .head {
        padding-bottom: 34px;
        margin: 0 14px 38px 0;
        border-bottom: 1px solid #eee;
        .title {
            text-align: center;
            font-size: 20px;
            color: @blue;
            font-weight: bold;
            margin-bottom: 27px;
        }
        .date {
            text-align: center;
            font-size: 16px;
            color: #666;
            
        }
    }
}

// 头部的样式
.header {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 0 12px 0 rgba(9, 9, 9, 0.12);
    z-index: 99;
    width: 100%;
    > div {
        width: 100%;
        &:nth-child(1) {
            background-color: #F3F3F3;
        }
        &:nth-child(2) {
            background-color: #fff;
        }
    }
    .normal-container {
        .flex;
        justify-content: space-between;
        align-items: center;
    }
    .top {
        height: 30px;
        .welcome {
            line-height: 30px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
        }
        .right {
            height: 100%;
            .flex;
            justify-items: center;
            .language {
                .flex;
                margin-right: 53px;
                height: 100%;
                line-height: 30px;
                a {
                    font-size: 14px;
                    color: #333;
                }
                .ch {
                    .flex;
                    align-items: center;
                    margin-right: 8px;
                    color: @blue;
                    .iconfont {
                        margin-right: 5px;
                    }
                }
                .en {
                    margin-left: 8px;
                    color: #333;
                }
            }
            .button {
                line-height: 30px;
                background-color: @blue;
                .flex;
                align-items: center;
                color: #fff;
                a {
                    padding: 0 8px 0 13px;
                    color: #fff;
                    font-size: 14px;
                    &:nth-child(2) {
                        padding: 0 13px 0 8px;
                    }
                }
            }
        }
    }
    .header-content {
        height: 82px;
        .flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            img {
                width: auto;
                height: 45px;
            }
        }
        .right {
            height: 100%;
            .flex;
            .header-nav-list {
                margin: 0;
                .flex;
                height: 100%;
                li {
                    height: 100%;
                    margin-left: 37px;
                    height: 100%;
                    position: relative;
                    .flex;
                    align-items: center;
                    a {
                        color: #333;
                        font-size: 18px;
                    }
                    &:nth-child(1) {
                        margin-left: 0;
                    }
                    .sub-menu {
                        position: relative;
                        background-color: #fff;
                        z-index: 6;
                        display: none;
                        box-shadow: 0 2px 7px 0 rgba(9, 9, 9, 0.12);
                        position: absolute;
                        top: 82px;
                        left: 50%;
                        // left: 0;
                        transform: translateX(-50%);
                        a {
                            height: 32px;
                            line-height: 32px;
                            border-bottom: 1px solid #eee;
                            // width: 100px;
                            white-space: nowrap;
                            padding: 0 15px;
                            display: block;
                            text-align: left;
                            font-size: 13px;
                            color: #666;
                            .text-cut;
                            &:hover,
                            &.active {
                                color: #fff;
                                background-color: @blue;
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                            &:first-child {
                                border-top: 2px solid @blue;
                            }
                        }
                        &::before {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            transform: translate(-50%, -100%);
                            content: '';
                            display: block;
                            border-width: 6px;
                            border-color: transparent transparent @blue transparent;
                            border-style: solid;
                        }
                    }
                    &.active,
                    &:hover {
                        // &::after {
                        //     content: '';
                        //     height: 2px;
                        //     width: 100%;
                        //     background-color: @blue;
                        //     display: block;
                        //     position: absolute;
                        //     bottom: 0;
                        //     left: 0;
                        // }
                        > a {
                            color: @blue;
                            font-weight: bold;
                        }
                    }
                    &:hover {
                        .sub-menu {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

// 底部的样式
.footer {
    p {
        margin-bottom: 0 !important;
    }
    color: #fff;
    > div {
        width: 100%;
        &:nth-child(1) {
            background-color: #333;
        }
        &:nth-child(2) {
            background-color: #222;
        }
    }
    .content {
        .main-content {
            padding: 37px 0;
            .flex;
            justify-content: space-between;
            font-size: 16px;
            color: #fff;
            .title {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 40px;
            }
            .quick-navigation {
                .title-navigation {
                    margin-bottom: -10px;
                    .flex;
                    flex-wrap: wrap;
                    width: 180px;
                    justify-content: space-between;
                    a {
                        color: #fff;
                        margin-bottom: 10px;
                    }
                }
            }
            .connect-us {
                margin: 0 50px;
                .connect-navigation {
                    margin-bottom: -10px;
                    li {
                        margin-bottom: 10px;
                        .flex;
                        align-items: baseline;
                        i {
                            margin-right: 10px;
                            flex-shrink: 0;
                        }
                    }
                }
            }
            .code {
                .flex;
                align-items: center;
                .img-box {
                    flex-shrink: 0;
                    width: 132px;
                    height: 132px;
                    padding: 8px;
                    border-radius: 10px;
                    overflow: hidden;
                    background-color: #fff;
                    > img {
                        width: 100%;
                        height: 100%;
                    }
                }
                p {
                    position: relative;
                    &::before {
                        position: absolute;
                        top: 0;
                        left: 6px;
                        content: '[';
                        display: block;
                        transform: rotateZ(90deg);
                    }
                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 6px;
                        content: ']';
                        display: block;
                        transform: rotateZ(90deg);
                    }
                    margin-left: 12px;
                    width: 1px;
                    padding: 17px 0;
                    text-align: center;
                }
            }
        }
        .friendship {
            border-top: 1px solid #fff;
            padding: 30px 0;
            .flex;
            align-items: baseline;
            .title {
                flex-shrink: 0;
                font-size: 18px;
                font-weight: bold;
            }
            .friendship-navigation {
                .flex;
                flex-wrap: wrap;
                margin-top: -8px;
                a {
                    color: #fff;
                    margin-top: 8px;
                    position: relative;
                    .flex;
                    align-items: center;
                    font-size: 16px;
                    padding: 0 20px;
                    border-right: 1px solid #fff;
                    &:last-child {
                        border-right: none;
                        padding-right: 0;
                    }
                }
            }
        }
    }
    .bottom {
        height: 28px;
        .flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 12px;
        line-height: 28px;
        .left {
            .flex;
            height: 100%;
            align-items: center;
            p {
                margin-right: 25px;
            }
        }
        a {
            color: #fff;
            
        }
    }
}

// 首页的样式
.page-index {
    // 首页的遮罩层
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.52;
    }

    // 轮播图
    .carousel-map {
        .img-box {
            width: 100%;
            > img {
                width: 100%;
                height: auto;
            }
        }
        .carousel-map-swiper {
            .swiper-pagination {
                bottom: 6%;
                &-bullet {
                    width: 14px;
                    height: 14px;
                    border: solid 2px @blue;
                    background: #fff;
                    opacity: 1;
                    &-active {
                        background: @blue;
                    }
                }
            }
            .button-next, 
            .button-prev {
                z-index: 2;
                position: absolute;
                top: 50%;
                cursor: pointer;
                i {
                    font-size: 48px;
                    color: #fff;
                }
            }
            .button-next {
                transform: translateY(-50%) rotateZ(180deg);
                right: 10%;
            }
            .button-prev {
                transform: translateY(-50%);
                left: 10%;
            }
            .swiper-button-last {
                i {
                    color: @blue;
                }
            }
        }
        
    }

    // 关于递爱福
    .about-module {
        padding: 69px 0 90px 0;
        .left {
            width: 100%;
            position: relative;
            > .img-box {
                position: absolute;
                z-index: 0;
                width: 406px;
                > img {
                    width: 100%;
                    height: auto;
                }
            }
            .title {
                margin: 40px 0 56px 0;
                font-size: 30px;
                font-weight: bold;
                color: @blue;
            }
            .father {
                width: 100%;
                .flex;
                align-items: center;
                justify-content: space-between;
                .article-content {
                    width: 561px;
                }
                .right {
                    .img-box {
                        .img-wrap(600px, 414px);
                        > img {
                            min-width: 100%;
                            min-height: 100%;
                        }
                    }
                }
            }
        }
        
    }

    // 主要服务
    .main-serve {
        background: url("@{image_url}main-serve-bg.png") no-repeat center;
        background-size: cover;
        padding: 50px 0 66px 0;
        ul {
            .number-left-list(30px, 3);
            li {
                width: 380px;
                height: 305px;
                position: relative;
                .img-box {
                    .img-wrap(100%, 305px);
                    > img {
                        transition: @transition;
                        min-width: 100%;
                        min-height: 100%;
                    }
                }
                a {
                    transition: @transition;
                    padding: 27px;
                    z-index: 3;
                    top: 0;
                    left: 0;
                    position: absolute;
                    color: #fff;
                    display: block;
                    height: 100%;
                    width: 100%;
                    text-align: center;
                    flex-direction: column;
                    .flex;
                    justify-content: center;
                    align-items: center;
                    .big {
                        transition: @transition;
                        width: 100%;
                        .title {
                            font-size: 40px;
                            margin-bottom: 7px;
                        }
                        p {
                            font-size: 18px;
                        }
                    }
                    .hover {
                        overflow: hidden;
                        transition: @transition;
                        width: calc(100%);
                        .line {
                            width: 40px;
                            background-color: #fff;
                            height: 2px;
                            margin: 15px auto;
                        }
                        .hover-text {
                            text-align: left;
                            width: calc(100%);
                            line-height: 1.8;
                            font-size: 14px;
                        }
                    }
                }
                &:hover {
                    .img-box {
                        > img {
                            transform: scale(1.1);
                        }
                    }
                    a {
                        justify-content: flex-end;
                         .hover {
                            display: block;
                         }
                    }
                }
            }
        }
    }

    // 公司视频
    .company-video {
        padding: 60px 0 76px 0;
        ul {
            .number-left-list(40px, 2);
            li {
                a {
                    padding: 10px 10px 0 10px;
                    .flex;
                    flex-direction: column;
                    background-color: #eee;
                    box-sizing: border-box;
                    // height: 400px;
                    box-sizing: border-box;
                    .top {
                        position: relative;
                        .img-box {
                            .img-wrap(100%, 321px);
                            > img {
                                transition: @transition;
                                min-width: 100%;
                                min-height: 100%;
                            }
                        }
                        .play-buttom {
                            z-index: 3;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            i {
                                color: #fff;
                                font-size: 48px;
                            }
                        }
                    }
                    .bottom {
                        position: relative;
                        flex-basis: 100%;
                        padding: 22px 20px;
                        text-align: center;
                        .flex;
                        flex-direction: column;
                        justify-content: center;
                        .title {
                            font-size: 18px;
                        }
                        .line {
                            display: none;
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 131px;
                            height: 3px;
                            background-color: @blue;
                        }
                    }
                }
                &:hover {
                    a {
                        .top {
                            .mask {
                                display: none;
                            }
                            .img-box {
                                > img {
                                    transform: scale(1.1);
                                }
                            }
                            .play-buttom {
                                display: none;
                            }
                        }
                        .bottom {
                            .title {
                                color: @blue;
                            }
                            .line {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    // 新闻动态
    .news-module {
        padding: 50px 0 56px 0;
        background: url("@{image_url}news-bg.png") no-repeat center;
        background-size: cover;
        .flex;
        flex-direction: column;
        justify-content: center;
        ul {
            margin-bottom: 44px;
            .number-left-list(30px, 3);
            li {
                a {
                    height: 376px;
                    display: block;
                    background-color: #fff;
                    .flex;
                    flex-direction: column;
                    .img-box {
                        .img-wrap(100%, 270px);
                        > img {
                            transition: @transition;
                            min-width: 100%;
                            min-height: 100%;
                        }
                    }
                    .content {
                        flex-basis: 100%;
                        padding: 22px 9px 20px 15px;
                        .title {
                            font-size: 18px;
                            margin-bottom: 10px;
                        }
                        .date {
                            font-size: 14px;
                            color: #999;
                        }
                    }
                }
                &:hover {
                    a {
                        .img-box {
                            > img {
                                transform: scale(1.1);
                            }
                        }
                    }
                    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.08);
                }
            }
        }
        .button {
            .flex;
            justify-content: center;
            a {
                color: #fff;
                padding: 9px 35px;
                background-color: #333333;
            }
        }
    }
}

// widget 的 banner-top
.banner-top {
    width: 100%;
    // position: relative;
    margin-bottom: 51px;
    .img-box {
        .img-wrap(100%, 340px);
        > img {
            min-height: 100%;
            min-width: 100%;
        }
    }

    // 新增加的
    height: 50px;
    background-color: @blue;

    .title {
        // position: absolute;
        // top: 50%;
        // left: 0px;
        // transform: translateY(-50%);
        color: #fff;
        // font-size: 40px;
        font-size: 24px;
        line-height: 50px;
        font-weight: bold;
    }
}

// widget：leftListNav
.left-navigation {
    ul {
        background-color: #F7F7F7;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        li {
            display: flex;
            align-items: center;
            transition: @transition;
            a {
                border-bottom: 1px solid #ECECEC;
                padding: 10px 20px;
                box-sizing: border-box;
                width: 300px;
                font-size: 14px;
                color: #666666;
                .flex;
                align-items: center;
                &::before {
                    flex-shrink: 0;
                    content: "";
                    display: block;
                    width: 10px;
                    height: 10px;
                    border: 1px solid #666666;
                    border-radius: 50%;
                    margin-right: 16px;
                }
            }
            &:hover, &.active {
                a {
                    border-color: @blue;
                    background-color: @blue;
                    color: #fff;
                    &::before {
                        background-color: #fff;
                        border-color: #fff;
                    }
                }
            }
        }
    }
}

// 02右边的内容
.two-detail-content {
    .flex;
    margin-bottom: 120px;
    .right {
        width: calc(100% - 340px);
    }
}

// 02-02理事简介
.page-director-detail {
    .right {
        .director-list {
            &-item {
                margin-top: 30px;
                &:nth-child(1) {
                    margin-top: 0;
                }
                .flex;
                padding-bottom: 29px;
                border-bottom: 1px solid #eee;
                .left {
                    flex-shrink: 0;
                    margin-right: 21px;
                    .img-box {
                        .img-wrap(145px, auto);
                        > img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                .introduction {
                    flex-basis: 100%;
                    h3 {
                        .flex;
                        margin-bottom: 20px;
                        font-size: 16px;
                        font-weight: bold;
                        .position {
                            margin-right: 10px;
                        }
                    }
                    p {
                        text-indent: 3em;
                        font-size: 14px;
                        color: #666;
                        line-height: 1.8;
                    }
                }
            }
        }
        .msg {
            margin-top: 40px;
            font-size: 14px;
            color: #666;
        }
    }
}

// 02-03常见问题
.page-question-detail {
    .right {
        .question-list {
            &-item {
                margin-bottom: 70px;
                h3 {
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 28px;
                }
                .article-content {
                    .question-ask {
                        text-indent: -1.5em;
                        padding-left: 1.5em;
                        margin-bottom: 20px;
                        position: relative;
                        a {
                            font-size: 14px;
                            text-decoration: none;
                            color: #666;
                            margin-bottom: 20px;
                            &:hover {
                                color: @blue;
                            }
                            .text {
                                line-height: 1.8;
                                display: inline;
                                
                            }
                        }
                        &.active {
                            a {
                                color: @blue;
                            }
                        }
                        .answer {
                            text-indent: 0;
                            padding: 10px 0 20px 0;
                            font-size: 14px;
                            line-height: 1.8;
                            color: #666;
                        }
                    }
                    
                }
            }
        }
    }
}

// loader
.box-shadow {
    // position: absolute;
    // right: -60px;
    // top: 0px;
    
    display: inline-block;
    // width: 50px;
    height: 0;
    font-size: 16px;
    -webkit-transition: .3s color, .3s border;
    transition: .3s color, .3s border;
    
}
[class*="loader-"] {
    margin-left: 30px;
    line-height: 10px;
    display: inline-block;
    width: 10px;
    height: 10px;
    color: rgba(200, 200, 200, 0.7);
    vertical-align: middle;
    pointer-events: none;
}
.loader-01 {
    border: 3px dotted currentcolor;
    border-radius: 50%;
    -webkit-animation: 1s loader-01 linear infinite;
    animation: 1s loader-01 linear infinite;
}
@-webkit-keyframes loader-01 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loader-01 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

// 表格加载的时候的loader
.table-serve {
    position: relative;
    .box-shadow {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        // width: 50px;
        height: 0;
        font-size: 30px;
        -webkit-transition: .3s color, .3s border;
        transition: .3s color, .3s border;
        
    }
    [class*="loader-"] {
        margin-left: 30px;
        line-height: 50px;
        display: inline-block;
        width: 50px;
        height: 50px;
        color: rgba(200, 200, 200, 0.7);
        vertical-align: middle;
        pointer-events: none;
    }
    .loader-01 {
        border: 10px dotted currentcolor;
        border-radius: 50%;
        -webkit-animation: 1s loader-01 linear infinite;
        animation: 1s loader-01 linear infinite;
    }
}

// 02-04大事记
.page-things-detail {
    
}

// 02-05基层会管理人员
.page-basis-manage-detail {
    .right {
        .content {
            .article-content {
                &:nth-child(1) {
                    margin-bottom: 20px;
                }
                &:nth-child(2) {
                    margin-top: 20px;
                }
            }
            .people {
                .people-list {
                    &-item {
                        font-size: 16px;
                        color: #666;
                        margin-top: 10px;
                        .post {
                            display: inline-block;
                        }
                        &:first-child {
                            margin-top: 0;
                        }
                        .name {
                            color: #666;
                            margin-left: 10px;
                            &:nth-child(2) {
                                margin-left: 0;
                            }
                        }
                        .has-detail {
                            text-decoration: underline;
                            color: @darkBlue;
                            cursor: pointer;
                        }
                        .people-popup {
                            z-index: 5;
                            display: none;
                            position: absolute;
                            top: 50%;
                            right: -26px;
                            transform: translate(100%, -50%);
                            box-sizing: border-box;
                            width: 627px;
                            background-color: #f7f7f7;
                            border: solid 1px #cccccc;
                            padding: 20px;
                            
                            &::before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translate(-100%, -50%);
                                height:0;
                                width:0;
                                border-width:16px 16px ;
                                border-style:solid;
                                border-color: transparent #cccccc transparent transparent ;
                                display:inline-block;
                                
                            }
                            &::after {
                                content: "";
                                position: absolute;
                                left: 1px;
                                top: 50%;
                                transform: translate(-100%, -50%);
                                height:0;
                                width:0;
                                border-width:16px 16px ;
                                border-style:solid;
                                border-color: transparent #f7f7f7 transparent transparent;
                                display:inline-block;
                            }
                            &.right-border {
                                right: auto;
                                left: -26px;
                                transform: translate(-100%, -50%);
                                &::before {
                                    left: auto !important;
                                    right: 0px;
                                    transform: translate(100%, -50%);
                                    border-color: transparent transparent transparent #ccc ;
                                }
                                &::after {
                                    left: auto;
                                    right: 1px;
                                    transform: translate(100%, -50%);
                                    border-color: transparent transparent transparent #f7f7f7 ;
                                }
                                
                            }
                            .img-box {
                                .img-wrap(166px, auto);
                                margin-right: 20px;
                                align-items: flex-start;
                                > img {
                                    width: 166px;
                                    height: auto;
                                }
                            }
                            .content {
                                flex-basis: 100%;
                                h3 {
                                    .flex;
                                    font-size: 16px;
                                    font-weight: bold;
                                    margin-bottom: 15px;
                                    p {
                                        &:nth-child(1) {
                                            margin-right: 10px;
                                        }
                                    }
                                }
                                pre {
                                    white-space: pre-wrap;
                                    width: calc(100% - 0px);
                                    font-size: 14px;
                                    color: #666;
                                    line-height: 1.8;
                                }
                            }
                        }
                        .people-box {
                            line-height: 1.8;
                            display: inline-block;
                            position: relative;
                        }
                    }
                }
                > p {
                    margin-bottom: 20px;
                    font-size: 16px;
                    color: #666;
                    &:nth-child(2) {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

// 02-06 联系我们
.page-connect {
    // 取消百度云的logo
    // .anchorBL {
    //     display: none;    
    // }
    // .anchorBL {
    //     display: none;    
    // }
    .BMap_scaleHBar {
        > img {
            display: none;
        }
    }
    .BMap_bubble_content {
        p {
            padding-left: 3em;
            text-indent: -3em;
        }
    }
    .map { 
        border: solid 1px #ededed;
        #mapBox {
            width: 100%;
            height: 526px;
            img{
                max-width: none; 
                max-height: none;
            }
        } 
    }
    .content-detail {
        margin-bottom: 20px;
        .left {
            margin-right: 45px;
            h3 {
                font-size: 24px;
                font-weight: bold;
                color: @black;
                
            }
            ul {
                li {
                    margin-left: 3em;
                    text-indent: -3em;
                    margin-top: 24px;
                    font-size: 18px;
                    color: @black;
                    &:nth-child(1) {
                        margin-top: 0;
                    }
                }
            }
        }
        .right {
            margin-top: 56px;
            .img-box {
                box-sizing: border-box;
                width: 186px;
                height: 186px;
                padding: 10px;
                .flex-center;
                border: solid 1px #ededed;
                > img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .content {
        .flex;
        flex-direction: column;
    }
}

// 03服务对象
.page-serve-obj-detail {
    .right {
        .content {
            width: 100%;
            > form {
                border-top: 1px solid #eee;
                width: 100%;
                .number-left-list(68px, 3);
                margin-top: 15px;
                font-size: 16px;
                color: #666;
                align-items: flex-end;
                margin-bottom: 60px;
                // margin-top: 30px;
                .item {
                    font-size: 16px;
                    color: #666;
                    input {
                        margin-top: 10px;
                        padding-left: 10px;
                        box-sizing: border-box;
                        width: 100%;
                        height: 36px;
                        border: solid 1px #cccccc;
                        font-size: 16px;
                        color: #666;
                    }
                    select {
                        padding-left: 10px;
                        font-size: 16px;
                        color: #666;
                        margin-top: 10px;
                        box-sizing: border-box;
                        width: 100%;
                        height: 36px;
                        border: solid 1px #cccccc;
                    }
                    .select2-container {
                        margin-top: 10px;
                        .select2-selection--single {
                            height: 36px;
                            .select2-selection__rendered {
                                line-height: 36px;
                            }
                            .select2-selection__arrow {
                                height: 36px;
                            }
                        }
                    }
                    .select2-container--default .select2-selection--single {
                        border: 1px solid #ccc;
                        border-radius: 0;
                    }
                }
                button {
                    cursor: pointer;
                    color: #fff;
                    font-size: 16px;
                    font-weight: bold;
                    width: 130px;
                    height: 44px;
                    .flex-center;
                    background-color: @blue;
                }
            }
        }
    }
    // 弹窗的样式
    .popup-wrapper {
        padding: 28px;
        .popup-header {
            .title {
                text-align: center;
            } 
        }
        .icon-close {
            top: -20px !important;
            right: 0 !important;
            transform: translateY(-100%);
        }
    }
    
}

// 05信息公开
.page-documents-detail {
    .right {
        .article-content {
            img {
                // padding: 20px;
                max-width: 100%;
                // height: auto;
            }
        }
    }
}

// 06-01列表
.page-new-list {
    .right {
        .bg-gray-title {
            margin-bottom: 53px;
        }
        .news-list {
            width: 100%;
            margin-top: -20px;
            &-item {
                margin-top: 20px;
                a {
                    border: solid 1px #eeeeee;
                    padding: 12px;
                    .flex;
                    align-items: center;
                    transition: @transition;
                    .img-box {
                        border: solid 3px #fff;
                        box-sizing: border-box;
                        transition: @transition;
                        margin-right: 20px;
                        background-color: #fff;
                        .img-wrap(289px, 175px);
                        > img {
                            transition: @transition;
                            min-width: 100%;
                            min-height: 100%;
                        }
                    }
                    .text {
                        width: calc(100% - 309px);
                        .top {
                            h3 {
                                font-size: 18px;
                                font-weight: bold;
                                margin-bottom: 10px;
                            }
                            .date {
                                font-size: 14px;
                            }
                        }
                        p {
                            margin-top: 20px;
                            font-size: 14px;
                            color: #666;
                            line-height: 1.8;
                        }
                    }
                }
                &:hover {
                    a {
                        color: #fff;
                        border: solid 1px @blue;
                        .img-box {
                            > img {
                                transform: scale(1.1);
                            }
                        }
                        .text {
                            p {
                                color: #fff;
                            }
                        }
                        background-color: @blue;
                    }
                }
            }
        }
        .page-buttom {
            justify-content: center;
        }
    }
}

// 06-02详情
.page-news-detail {
    .right {
        box-sizing: border-box;
        background-color: #fafafa;
        padding: 0 35px 55px 35px;
        .top {
            padding: 50px 14px 34px 14px;
            border-bottom: 1px solid #eee;
            margin-bottom: 40px;
            h3 {
                font-weight: bold;
                text-align: center;
                font-size: 20px;
                color: @blue;
                margin-bottom: 27px;
            }
            .date {
                text-align: center;
                font-size: 16px;
                color: #666;
            }
        }
        .article-content {
            width: 100%;
        }
    }
}

// 06-03志愿者表彰
.page-volunteer-detail {
    table {
        tr {
            td {
                &:nth-child(1) {
                    width: 9%;
                }
                &:nth-child(2) {
                    width: 13%;
                }
                &:nth-child(3) {
                    width: 11%;
                }
                &:nth-child(4) {
                    width: 11%;
                }
            }
        }
    }
    .introduce-text {
        font-size: 16px;
        margin-bottom: 32px;
    }
}

// 06-04图片库大列表
.page-pic-warehouse-detail {
    .right {
        .pic-warehouse-list {
            .number-left-list(40px,3);
            li {
                a {
                    transition: @transition;
                    box-sizing: border-box;
                    width: 100%;
                    display: block;
                    padding: 2px;
                    background-color: #f7f7f7;
                    .img-box {
                        .img-wrap(100%, 190px);
                        > img {
                            transition: @transition;
                            min-width: 100%;
                            min-height: 100%;
                        }
                    }
                    .p {
                        .flex-center;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 10px 20px;
                        text-align: center;
                        font-size: 16px;  
                        div {
                            width: 100%;
                        }
                    }
                }
                &:hover {
                    a {
                        color: #fff;
                        background-color: @blue;
                        .img-box {
                            > img {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
        .page-buttom {
            justify-content: center;
        }
    }
}

// 06-05图片库内列表
.page-pic-list-detail {
    .right {
        .pic-list {
            .number-left-list(40px,3);
            li {
                transition: @transition;
                box-sizing: border-box;
                width: 100%;
                display: block;
                padding: 2px;
                .img-box {
                    .img-wrap(100%, 190px);
                    > img {
                        transition: @transition;
                        min-width: 100%;
                        min-height: 100%;
                    }
                }
                .p {
                    .flex-center;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 10px 20px;
                    text-align: center;
                    font-size: 16px;  
                    div {
                        width: 100%;
                    }
                }
                &:hover {
                    color: #fff;
                    background-color: @blue;
                    .img-box {
                        > img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        .sum-pic {
            margin-top: -7px;
            font-size: 16px;
            margin-bottom: 35px;
            padding-left: 17px;
        }
        .page-buttom {
            justify-content: center;
        }
    }
}
div.mfp-close{
    top: auto !important;
    bottom: -28px;
    right: auto !important;
    padding: 0 !important;
    text-align: center !important;
    &:after{
        
        content: '';
        .icon-close-image;
    }
}
button.mfp-close{
    display: none !important;
}
.mfp-arrow {
    &::before {
      border: none !important;
    }
    &::after {
      border: none !important;
      background: url("@{icon_url}toright.png") no-repeat center;
      width: 33px !important;
      height: 56px !important;
      background-size: 33px 56px;
    }
    &.mfp-arrow-left {
      &::after {
        transform: rotateZ(-180deg);
      }
    }
    
}


// 大事记样式
.matters_container{
    display: none;
}
.matters-container{
    position: relative;
    padding-bottom: 50px;
    &:after{
      content: '';
      display: inline-block;
      position: absolute;
      height: 100%;
      width: 1px;
      border-left: 1px dashed #e2e2e2;
      left: 76px;
      top: 0;
      z-index: 1;
    }
    .ant-collapse{
      border: none;
      background-color: transparent;
      > .ant-collapse-item{
        border-bottom: 0;
      }
      .ant-collapse-content-box{
        padding: 0;
      }
    }
    .ant-collapse-content{
      border-top: 0;
    }
  
    .year-wrap{
      > .ant-collapse-item{
        margin-bottom: 40px;
        > .ant-collapse-header {
        cursor: pointer !important;
          width: 92px;
          height: 30px;
          background-color: @blue;
          border-radius: 15px !important;
          color: #fff;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          font-size: 24px;
          line-height: 30px;
          padding-left: 10px;
          z-index: 2;
          .ant-collapse-arrow{
            position: absolute;
            left: auto;
            right: 10px;
            top: 10px;
            font-weight: bold;
          }
        }
      }
    }
    .day-wrap{
      > .ant-collapse-item{
        &.ant-collapse-no-arrow {
          .ant-collapse-header{
            padding-left: 0;
          }
        }
        .ant-collapse-header{
            cursor: pointer !important;
          padding: 20px 0;
          padding-bottom: 6px;
          .ant-collapse-extra{
            float: none;
            .matter-day-title{
              .flex;
              position: relative;
              z-index: 3;
              > .l{
                .flex;
                flex-shrink: 0;
              }
              .r{
                display: flex;
                flex: 1;
                justify-content: space-between;
                &.close-style{
                  padding-bottom: 26px;
                  border-bottom: 1px solid @border;
                }
              }
              .day{
                font-size: 20px;
                color: @blue;
                font-weight: bold;
              }
              .icon-calendar-wrap{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: @blue;
                color: #fff;
                .flex-center;
                margin-left: 13px;
                margin-right: 23px;
                > .iconfont{
                  font-size: 22px;
                }
              }
              .title{
                font-size: 16px;
                color: @black ;
                font-weight: bold;
              }
            }
          }
        }
        .ant-collapse-content{
          &-box{
            margin-left: 114px;
            padding: 30px;
            margin-bottom: 30px;
            position: relative;
            background-color: #f7f7f7;
            .flex;
            .matter-text-content{
              line-height: 1.8;
              flex: 1;
              color: @light-black;
              margin-top: -4px;
            }
            > .img-box{
              width: 266px;
                height: 180px;
              flex-shrink: 0;
              margin-right: 24px;
            }
            &:after{
              content: '';
              display: inline-block;
              position: absolute;
              left: 0;
              bottom: -30px;
              height: 1px;
              background-color: @border;
              width: 100%;
            }
            .hover-img;
          }
        }
        &:first-child{
          margin-top: 24px;
        }
      }
      .matter-day-title{
        .flex;
        position: relative;
        z-index: 3;
        &.matter-day-title_no-data{
          padding-top: 26px;
        }
        > .l{
          .flex;
          flex-shrink: 0;
        }
        .r{
          display: flex;
          flex: 1;
          justify-content: space-between;
          &.close-style{
            padding-bottom: 26px;
            border-bottom: 1px solid @border;
          }
        }
        .day{
          font-size: 20px;
          color: @blue;
          font-weight: bold;
        }
        .icon-calendar-wrap{
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: @blue;
          color: #fff;
          .flex-center;
          margin-left: 13px;
          margin-right: 23px;
          > .iconfont{
            font-size: 22px;
          }
        }
        .title{
          font-size: 16px;
          color: @black ;
          font-weight: bold;
        }
      }
    }
    .icon-open-close {
        flex-shrink: 0;
        margin-top: 6px;
    }
}

// 富文本中的表格
.article-content {
    table {
        box-sizing: border-box;
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #666;
        thead {
            background-color: #f7f7f7;
            font-weight: bold;
            td {
                padding: 10px;
                white-space: pre-wrap;
            }
        }
        tr {
            td {
                border: solid 1px #dddddd;
                height: 50px;
            }
        }
        tbody {
            .firstRow {
                background-color: #f7f7f7;
                font-weight: bold;
                td {
                    padding: 10px;
                }
            }
            td {
                padding: 0 10px;
            }
        }
    }
}

// 分页的样式
.page-buttom {
    padding: 50px 0;
    line-height: 30px;
    .flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    text-align: center;
    .page-left {
        margin-right: 32px;
        .flex;
        align-items: center;
        .number-a {
            .flex;
            .number {
                display: block;
                width: 30px;
                height: 30px;
                border: solid 1px #cccccc;
                margin-left: 10px;
                &:nth-child(1) {
                    margin-left: 0;
                }
                &.on {
                    background-color: @blue;
                    color: #fff;
                    pointer-events: none;
                }
            }
            .omit {
                margin-left: 10px;
            }
        }
    }
    .page-prev,
    .page-next {
        width: 70px;
        height: 30px;
        border: solid 1px #cccccc;
    }
    .page-prev {
        margin-right: 12px;
    }
    .page-next {
        margin-left: 12px;
    }
    .page-right {
        .flex;
        input {
            text-align: center;
            padding: 0 5px;
            width: 40px;
            height: 30px;
            border: solid 1px #cccccc;
            color: #666;
        }
        .btn {
            background-color: #fff;
            margin-left: 13px;
            width: 60px;
            height: 30px;
            border: solid 1px #cccccc;
            color: #666;
        }
    }
}

main {
    padding-top: 112px;
}

// 服务对象中得表格
.table-serve {
    table {
        align: center;
        width: 100%;
        font-size: 14px;
        color: #666;
        thead {
            background-color: #f7f7f7;
            font-weight: bold;
            color: #666;
        }
        td {
            display: table-cell; 
            vertical-align: middle;
            border: solid 1px #dddddd;
            padding: 10px;
            text-align: center;
            pre {
                white-space: pre-wrap;
            }
            a {
                color: @blue;
                text-decoration: underline;
            }
        }
    } 
    .one-distance {
        min-width: 65px;
        max-width: 75px;
    }
    .two-distance {
        min-width: 120px;
        max-width: 130px;
    }
    .three-distance {
        min-width: 90px;
        max-width: 100px;
    }
    .four-distance {
        min-width: 60px;
        max-width: 70px;
    }
}

// 取消ie下面的select选择框
// select::-ms-expand { display: none; }

// 登录注册页面
.page-login {
    height: 100%;
    background-color: #f3f3f3;
    .page-login-top {
        width: 100%;
        background-color: @blue;
        height: 50px;
        > div {
            line-height: 50px;
            color: #fff;
            font-size: 24px;
        }
    }
    .content {
        background-color: #f3f3f3;
        margin: 80px auto;
        ul {
            .number-left-list(30px, 2);
            li {
                padding: 35px 120px;
                background-color: #fff;
                h3 {
                    font-size: 22px;
                    color: #666;
                    margin-bottom: 35px;
                    text-align: center;
                }
                .btns {
                    &-item {
                        margin-bottom: 15px;
                        position: relative;
                        > a {
                            transition: @transition;
                            position: relative;
                            background-color: #28a7dc;
                            color: #fff;
                            font-size: 14px;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                            display: block;
                            &.has-menu {
                                &::after {
                                    content: '';
                                    width: 0;
                                    border-width: 10px;
                                    border-style: solid;
                                    border-color: transparent transparent transparent #fff;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    right: 20px;
                                }
                            }
                            
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .btns-item-menu {
                            transition: @transition;
                            width: 100%;
                            background-color: #fff;
                            z-index: 6;
                            display: none;
                            box-shadow: 0 2px 12px 0 rgba(9, 9, 9, 0.12);
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            // left: 0;
                            transform: translateY(100%);
                            a {
                                background-color: #f3f3f3;
                                border-bottom: 1px solid #eee;
                                white-space: nowrap;
                                padding: 15px;
                                display: block;
                                text-align: center;
                                font-size: 14px;
                                color: #666;
                                .text-cut;
                                &:hover,
                                &.active {
                                    color: #333;
                                    background-color: #fff;
                                }
                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }
                        &:hover {
                            > a {
                                background-color: @blue;
                                &.has-menu {
                                    &::after {
                                        content: '';
                                        width: 0;
                                        border-width: 10px;
                                        border-style: solid;
                                        border-color: #fff transparent transparent transparent;
                                        position: absolute;
                                        top: 50%;
                                        transform: translateY(-5px);
                                        right: 20px;
                                    }
                                }
                            }
                            .btns-item-menu {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}